.root {
  padding: 20px;
  background-color: var(
    --notification-color,
    var(--mantine-primary-color-filled)
  );

  &::before {
    background-color: var(--mantine-color-white);
  }
}
.description,
.title {
  color: var(--mantine-color-white) !important;
}
