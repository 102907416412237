body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.templatePlaceholder {
  color: white;
  background-color: gray;
  padding: 2px;
}

.react-pdf__Page {
  border: 1px solid darkgrey;
  border-radius: 10px;
  height: 842px;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}

.mantine-Modal-title {
  font-size: 20px;
}

/* Organization Chart */
.oci-spinner::before {
  border-top-color: var(--mantine-color-blue-4) !important;
  border-left-color: var(--mantine-color-blue-4) !important;
  border-bottom-color: var(--mantine-color-blue-4) !important;
}

.oc-mask {
  border-radius: 20px;
}

.recharts-layer {
  outline: none;
}
